<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card title="الفلترة" class="mb-2" title-color="primary">
      <vs-popup class="holamundo" title="تنزيل الطلبات" :active.sync="ExportPopUp">
          <div>
            <label class="vs-input--label mt-5">من تاريخ</label>
            <datepicker class="w-full" format="dd/MM/yyyy" v-validate="'date_format:dd/MM/yyyy'" name="delivery_date"
              v-model="from_date" tabindex="2"></datepicker>
            <label class="vs-input--label mt-5">الى تاريخ</label>
            <datepicker class="w-full" format="dd/MM/yyyy" v-validate="'date_format:dd/MM/yyyy'" name="delivery_date"
              v-model="to_date" tabindex="2"></datepicker>

            <div class="mb-6">&#8203;</div>
            <vs-button @click="getExport()" color="success" type="filled" icon-pack="feather" icon="icon-check">
              تنزيل</vs-button>
          </div>
        </vs-popup>
      <div class="vx-row  align-center">
       
        <div class="vx-col md:w-1/3 flex">
          <vs-dropdown vs-trigger-click class="md:w-1/2 dd-actions cursor-pointer mr-4 mb-4">
            <div class="
            p-4
            shadow-drop
            rounded-lg
            d-theme-dark-bg
            cursor-pointer
            flex
            items-center
            justify-center
            text-lg
            font-medium
            w-32 w-full
          ">
              <span class="mr-2">البحث ب {{ getSearchByKey(search_by) }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="search_by = 'starts_with[id]'">
                <span class="flex items-center">

                  <span>رقم الطلب</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'starts_with[user.username]'">
                <span class="flex items-center">

                  <span>اسم المستخدم</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'contains[items.product.sku]'">
                <span class="flex items-center">

                  <span>رقم المنتج</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'starts_with[user.phone]'">
                <span class="flex items-center">

                  <span>رقم المستخدم</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'exact[user.code.code]'">
                <span class="flex items-center">

                  <span>كود الصالة </span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>

          </vs-dropdown>
          <vs-input placeholder="بحث" type="text" class="md:w-3/4" name="note" v-model="searchQuery" />
        </div>
        <div class="vx-col md:w-1/3">
          <v-select placeholder="الطلب من" dir="rtl" v-model="created_from" label="label" :reduce="(item) => item.value"
            :options="filter" />
        </div>
        <div class="vx-col md:w-1/3">
          <v-select label="lable" placeholder="حالة الطلب" dir="rtl" v-model="order_status_selection"
            :reduce="(item) => item.value" :options="orderStatuses">
          </v-select>
        </div>
        <div class="vx-col md:w-1/3">
          <v-select label="username" placeholder="المندوب" dir="rtl" v-model="delivery_id" :reduce="(item) => item.value"
            :options="delivery">
          </v-select>
        </div>

      </div>
      <div class="vx-row justify-end">
        <vs-button class="mr-3" color="#7367F0" type="filled" @click="search()">
          بحث
        </vs-button>
        <vs-button class="mr-3" color="warning" type="border" @click="reset()">
          إعادة تعيين
        </vs-button>
      </div>

    </vx-card>
    <vs-table ref="table" multiple v-model="selected" :max-items="itemsPerPage" :data="orders">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ACTION - DROPDOWN -->


          <!-- ADD NEW -->
          <a @click="$router.push({ name: 'new-order' })" href="/#/orders/new" class="
              btn-add-new
              p-3
              mb-4
              mr-4
              rounded-lg
              cursor-pointer
              flex
              items-center
              justify-center
              text-lg
              font-medium
              text-base text-primary
              border border-solid border-primary
            ">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />

            <span class="ml-2 text-base text-primary">اضافة طلب</span>
          </a>
          <!-- DOWNLOAD PDF-->
          <div @click="exportPdf" class="
              btn-add-new
              p-3
              mb-4
              mr-4
              rounded-lg
              cursor-pointer
              flex
              items-center
              justify-center
              text-lg
              font-medium
              text-base text-primary
              border border-solid border-primary
            ">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />

            <span class="ml-2 text-base text-primary">تنزيل PDF</span>
          </div>
          <div @click="exportExcel" class="
              btn-add-new
              p-3
              mb-4
              mr-4
              rounded-lg
              cursor-pointer
              flex
              items-center
              justify-center
              text-lg
              font-medium
              text-base text-primary
              border border-solid border-primary
            ">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />

            <span class="ml-2 text-base text-primary">تنزيل Excel</span>
          </div>


        </div>

        <!-- ITEMS PER PAGE -->


      </div>

      <template slot="thead">
        <vs-th sort-key="id">رقم الطلب</vs-th>
        <vs-th sort-key="total">المبلغ الإجمالي</vs-th>
        <vs-th sort-key="created_at">تاريخ التوصيل</vs-th>

        <vs-th>للزبون</vs-th>
        <vs-th>رقم الزبون</vs-th>
        <vs-th>سعر الدعم</vs-th>
        <vs-th>المدفوع</vs-th>


        <vs-th sort-key="order_status_id">حالة الطلب</vs-th>
        <vs-th sort-key="created_at">الطلب من</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.id }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.total }}LYD</p>
            </vs-td>

            <vs-td>
              <vs-chip class="product-order-status">
                {{ tr.delivery_date }}
              </vs-chip>
            </vs-td>


            <vs-td>
              {{ tr.user ? tr.user.username : 'هذا المستخدم قام بمسح حسابه' }}
            </vs-td>
            <vs-td>
              {{ tr.user ? tr.user.phone : 'هذا المستخدم قام بمسح حسابه' }}
            </vs-td>

            <vs-td>
              {{ tr.support }}
            </vs-td>
            <vs-td>
              {{ tr.payment_method_id !== 1 ? `${tr.paid} د.ل ` : 'مدفوع نقدي' }}
            </vs-td>

            <vs-td>
              <vs-chip :color="getOrderStatusColor(tr.order_status.name)" class="product-order-status">
                {{ tr.order_status.name }}</vs-chip>
            </vs-td>
            <vs-td>
              {{ tr.created_from }}
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <a @click="editData(tr)" :href="'/#/orders/edit/' + tr.id + '?page=' + current_Page">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
              </a>

              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                @mousedown="confirm(tr.id)" />
              <a @mousedown="printInvoice(tr)" :href="'/#/orders/invoice'">
                <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" />
              </a>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination class="mt-4" :total="lastPage" v-model="current_Page" @change="getData(current_Page)">
    </vs-pagination>

    <!-- HIDDEN TABLE OF ORDERS USED FOR PRINTING PDF-->
    <div style="display: none" id="table_body">
      <div style="
          width: 100%;
          align-items: center;
          border: 5px solid;
          margin-bottom: 5px;
          box-sizing: border-box;
          margin-bottom: 10px;
        ">
        <div style="
            display: flex;
            justify-content: space-around;
            padding-left: 15px;
            margin-bottom: 10px;
            margin-top: 10px;
          ">
          <span>Total Count of Dilvered Orders: {{ ReportOrders.length }}</span>
          <span>Whole Total: {{ ReportOrders.total }}</span>
          <span>Total Discounts: {{ ReportOrders.discount }}</span>
          <span>Total Delivery Cost: {{ ReportOrders.delivery }}</span>
        </div>
      </div>
      <table id="table_products_pdf" style="width: 100%">
        <thead id="table_products_pdf_header" style="background-color: black">
          <tr id="table_products_pdf_header_row"></tr>
        </thead>
        <tbody id="table_body">
          <tr style="width: 100%; margin-top: 1rem; margin-bottom: 1rem" v-for="(order, index) in ReportOrders"
            :key="index">
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.id }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.user ? order.user.username : 'هذا المستخدم قام بمسح حسابه' }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              <div v-for="(item, key) in order.items" :key="key">
                {{ item.product ? item.product.sku + '(' + item.quantity + ' ,' + item.product.price + ')' : "" }}
              </div>
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.delivery_date }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.total }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.discount }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ (order.total - order.discount) + order.delivery_price }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.delivery_price }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.support }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.payment_method.name }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ order.note }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moduleOrder from "@/store/orders/moduleOrder.js";
import moduleUser from "@/store/users/moduleUser.js";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    vSelect,
    Datepicker
  },
  data() {
    return {
      order_status_selection: null,
      loading: false,
      ReportOrders: [],
      selected: [],
      orders: [],
      delivery: [],
      popupActivo: false,
      search_by: 'starts_with[id]',
      filter: [
        {
          value: "user",
          label: "من مستخدم",
        },
        {
          value: "admin",
          label: "من مشرف",
        },
      ],
      orderStatuses: [
        {
          value: "1",
          lable: "قيد المراجعة",
        },
        {
          value: "2",
          lable: "قيد التجهيز",
        },
        {
          value: "3",
          lable: "تم التوصيل",
        },
        {
          value: "4",
          lable: "تم الرفض",
        },
      ],
      searchQuery: '',
      params: {},
      //used for pdf export
      dilveredOrders: [],
      AlldilveredOrders: [],
      from_date: '',
      to_date: '',
      ExportPopUp: false,
      dilveredOrdersTotal: {
        total: 0,
        discount: 0,
        delivery: 0,
      },
      current_Page: Number(this.$route.query.page) || 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      url: process.env.VUE_APP_BASE_URL,
      order_id: "",
      created_from: ''
    };
  },

  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.orders.length;
    },
  },
  methods: {
    
    search() {
      this.params[this.search_by] = this.searchQuery
      this.params['exact[created_from]'] = this.created_from
      this.params['exact[order_status_id]'] = this.order_status_selection
      this.params['exact[delivery_id]'] = this.delivery_id
      this.getData(1)
    },
    toUrlQuery(params) {
      return `?${Object.keys(params).map(function (key) {

        return `${key}=${params[key]}`
      }).join('&')}`;

    },
    getExport() {
      this.params["date_min[created_at]"] = this.formatDate(this.from_date);
      this.params["date_max[created_at]"] = this.formatDate(this.to_date);
      window.location = `${this.url}/api/orders/export${this.toUrlQuery(this.params)}`;
    },
    getSearchByKey(value) {
      if (value == 'starts_with[id]') return 'رقم الطلب';
      if (value == 'starts_with[user.username]') return 'اسم المستخدم';
      if (value == 'starts_with[user.phone]') return 'رقم المستخدم';
      if (value == 'contains[items.product.sku]') return 'رقم المنتج';
      if (value == 'exact[user.code.code]') return 'كود الصالة';
    },
    async exportPdf() {
      this.$vs.loading();
      var columns = [
        "Order ID",
        "Customer Name",
        "Sku's",
        "Date",
        "Total",
        "discount",
        "Net Total",
        "Delivery Cost",
        'Support',
        "Payment Method",
        "Note",
      ];
      // get the products

      await this.$store.dispatch("order/fetchAllOrders", this.params).then((response) => {
        this.$vs.loading.close();
        this.ReportOrders = response.data.orders
        //total of totla
        let Totaloftotal = 0;
        let TotalDiscount = 0;
        let TotalDelivery = 0;
        this.ReportOrders.forEach((order) => {

          Totaloftotal = Totaloftotal + (order.total - order.discount);
          TotalDiscount = TotalDiscount + order.discount;
          TotalDelivery = TotalDelivery + order["delivery_price"];
        });

        this.ReportOrders.total = Totaloftotal;
        this.ReportOrders.discount = TotalDiscount;
        this.ReportOrders.delivery = TotalDelivery;

        setTimeout(() => {
          let printContents = document.getElementById("table_body");
          printContents.style.display = "table";
          var windosw = window.open();
          windosw.document.body.innerHTML = printContents.innerHTML;
          printContents.style.display = "none";

          let style = document.createElement("style");
          style.innerHTML =
            " @import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap'); body:{ font-family: 'Cairo' } #table_products_pdf_header > tr >th { color: white !important; font-size: 14px; text-align: center; } @page { size: A4; margin: 11mm 17mm 17mm 17mm; } @media print { thead{ -webkit-print-color-adjust: exact; background-color: black; } footer { position: fixed; bottom: 0; } #table-header{ position: fixed; top: 0; left: 0; } html, body { width: 210mm; height: 297mm; -webkit-print-color-adjust: exact; } }";
          windosw.document.body.style.fontFamily = "Cairo";
          windosw.document.head.appendChild(style);

          columns.forEach((h) => {

            let th = windosw.document.createElement("th");
            th.innerHTML = h;
            windosw.document
              .getElementById("table_products_pdf_header_row")
              .appendChild(th);
          });

          setTimeout(() => {
            //windosw.print()
          }, 100);
        }, 800);
      }).catch(() => {
        this.$vs.loading.close();
      })
    },
    reset() {
      this.params = {}
      this.getData(1)
    },
    exportExcel() {
      this.ExportPopUp = true
    },
    getData(page) {
      this.$vs.loading();
      this.params['page'] = page
      this.$store.dispatch("order/fetchAllOrders", this.params).then((response) => {
        this.AlldilveredOrders = response.data.orders.filter(
          (order) => order.order_status.id === 3
        );
        //total of totla
        let Totaloftotal = 0;
        let TotalDiscount = 0;
        let TotalDelivery = 0;

        this.AlldilveredOrders.forEach((order) => {
          Totaloftotal = Totaloftotal + (order.total - order.discount);
          TotalDiscount = TotalDiscount + order.discount;
          TotalDelivery = TotalDelivery + order["delivery_price"];
        });

        this.dilveredOrdersTotal.total = Totaloftotal;
        this.dilveredOrdersTotal.discount = TotalDiscount;
        this.dilveredOrdersTotal.delivery = TotalDelivery;
      });

      this.$store.dispatch("order/fetchOrders", this.params).then((response) => {
        this.orders = response.data.orders.data;
        this.itemsPerPage = response.data.orders.per_page;
        this.totalItems = response.data.orders.total;
        this.lastPage = response.data.orders.last_page;
        this.dilveredOrders = this.orders.filter(
          (order) => order.order_status.id === 3
        );

        this.$vs.loading.close();
      });
    },
    printInvoice(tr) {
      localStorage.setItem("order_id", tr.id);
    },
    editData(tr) {
      this.$router.push({
        name: "edit-order",
        params: { id: tr.id },
        query: { page: this.current_Page },
      });
    },
    confirm(id) {
      this.order_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من مسح هذا الطلب؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    deleteData() {
      this.$store
        .dispatch("order/deleteOrder", this.order_id)
        .then(() => {
          this.getData(1);
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم مسح المنتج بنجاح",
            color: "success",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },

    getOrderStatusColor(status) {
      if (status == "قيد المراجعة") return "warning";
      if (status == "تم التوصيل") return "success";
      if (status == "تم الرفض") return "danger";
      if (status == "قيد التجهيز") return "primary";
      return "primary";
    },

    random() {
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      }
      let color = `rgb(${getRandomInt(0, 255)},${getRandomInt(
        0,
        255
      )},${getRandomInt(0, 255)})`;

      this.$vs.notify({
        title: "Color",
        text: "Lorem ipsum dolor sit amet, consectetur",
        color: color,
      });
    },
  },
  mounted() {
    this.isMounted = true;
    this.$vs.loading();
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("user", moduleUser);
      moduleUser.isRegistered = true;
    }
    if (!moduleOrder.isRegistered) {
      this.$store.registerModule("order", moduleOrder);
      moduleOrder.isRegistered = true;
    }
    this.$store.dispatch("user/fetchDelivery", { 'all': true }).then((response) => {
      this.delivery = response.data.delivery.data;
    });
  },
};
</script>

<style lang="scss">
.vs-popup--content {
  height: 100%;
}

#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
